import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { StorageEnums } from "../../enums/storageEnums";
import { AuthService } from "../../services/authService";
import { ProjectService } from "../../services/project.service";
import { StorageService } from "../../services/storage.service";

const projectService = new ProjectService();
const authService = new AuthService();
const storageService = new StorageService();

const GlideAuthenticator = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { id } = useParams();
  const navigate = useNavigate();

  console.log(id, searchParams);
  useEffect(() => {
    if (!token || !id) {
      return;
    }

    async function checkIfParamsAreValid(
      refreshToken: string,
      glideId: string
    ) {
      try {
        const authResponse = await authService.handleRefreshToken(refreshToken);
        storageService.setKey(StorageEnums.CREDENTIALS, authResponse);
        const projectResponse = await projectService.fetchProjectByGlideId(
          glideId
        );

        navigate(
          `/dashboard/details/${projectResponse.data.sections[0].projectId}/section/${projectResponse.data.sections[0].id}`
        );
      } catch (error) {}
    }

    checkIfParamsAreValid(token, id);
  }, [token, id]);

  return <></>;
};
export default GlideAuthenticator;
